import {computed, Injectable, signal} from '@angular/core';
import {BehaviorSubject, map, Observable, switchMap, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {Constants} from "../../utils/constants";
import {StrapiResponse} from "../../models/response.model";
import {Encadrant} from "../../models/encadrant";
import {Router} from "@angular/router";
import {UserInMemoryService} from "../user-in-memory/user-in-memory.service";
import {AuthRespons, User} from "../../models/AuthResponse.model";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  readonly isLoggedIn$: Observable<boolean>;
  readonly user = signal<User | null>(null);
  readonly isAdmin = computed(() => this.user()?.isAdmin ?? false);
  readonly forcePasswordReset = computed(() => this.user()?.forcePasswordReset ?? false);

  private tokenSubject: BehaviorSubject<boolean>;
  private readonly LOGIN_URL = '/api/auth/local';
  private readonly ENCADRANTS_URL = (email: number) => `/api/encadrants?populate=group&filters[users_permissions_user][id][$eq]=${email}`;
  private readonly REST_PASSWORD_URL = '/api/users/reset-password';

  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router,
              private userInMemoryService: UserInMemoryService) {
    this.tokenSubject = new BehaviorSubject<boolean>(this.isLoggedIn());
    this.isLoggedIn$ = this.tokenSubject.asObservable();
  }

  login(identifier: string, password: string): Observable<any> {
    return this.http.post<any>(this.LOGIN_URL, { identifier, password }).pipe(
      tap((authResponse: AuthRespons) => {
        this.saveToken(authResponse);
        this.user.set(authResponse.user);
      }),
      switchMap((response) => this.fetchEncadrantByUserId(response.user.id)),
    );
  }

  fetchConnectedUser(): void {
    this.http.get<User>('/api/users/me').pipe(
      tap(user => this.user.set(user)),
    ).subscribe();
  }

  fetchEncadrantByUserId(id: number): Observable<Encadrant> {
    return this.http.get<StrapiResponse<Encadrant[]>>(this.ENCADRANTS_URL(id)).pipe(
      map((encadrants: StrapiResponse<Encadrant[]>) => encadrants.data[0]),
      tap((encadrant: Encadrant) => this.setGroup(encadrant?.group?.identifier))
    )
  }

  logout(): void {
    this.cookieService.delete(Constants.TOKEN_NAME);
    this.cookieService.delete(Constants.GROUP_NAME);
    this.tokenSubject.next(false);
    this.userInMemoryService.clear();
    // We don't use the router here to be sure that everything cached is correctly remove
    window.location.assign('login')
  }

  updatePassword(currentPassword: string, password: string, passwordConfirmation: string): Observable<AuthRespons> {
    return this.http.post<AuthRespons>('/api/auth/change-password', {currentPassword, password, passwordConfirmation}).pipe(
      tap((response) =>
        this.user.set({...response.user, forcePasswordReset: false})
      )
    );
  }

  isLoggedIn(): boolean {
    return this.cookieService.check(Constants.TOKEN_NAME);
  }

  getToken(): string {
    return this.cookieService.get(Constants.TOKEN_NAME);
  }

  private saveToken(authResponse: any) {
    this.setToken(authResponse);
    this.cookieService.set(Constants.USER_ID, `${authResponse.user.id}`, {expires: 1});
    this.tokenSubject.next(true);
  }

  private setToken(authResponse: any) {
    this.cookieService.set(Constants.TOKEN_NAME, authResponse.jwt, {
      expires: 1,
    });
  }

  private setGroup(groupId?: string) {
    if(!groupId) {
      return;
    }

    this.cookieService.set(Constants.GROUP_NAME, groupId, {
      expires: 1,
    });
  }
}
