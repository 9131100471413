import {Component, signal, Signal, WritableSignal} from '@angular/core';
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {Observable} from "rxjs";
import {ApiLoadingService} from "../../services/api-loading/api-loading.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  readonly isLoggedIn$: Observable<boolean>
  readonly isAdmin: Signal<boolean | null>;
  readonly forcePasswordReset: Signal<boolean | null>;
  readonly isLoading: Signal<boolean | null>;

  constructor(private authenticationService: AuthenticationService, private apiLoadingService: ApiLoadingService ) {
    this.isLoggedIn$ = authenticationService.isLoggedIn$;
    this.isAdmin = authenticationService.isAdmin;
    this.forcePasswordReset = authenticationService.forcePasswordReset;
    this.isLoading = apiLoadingService.isLoading;
  }


  logout(): void {
    this.authenticationService.logout();
  }
}
