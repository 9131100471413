import {computed, Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiLoadingService {
  private requestCount = signal(0);

  isLoading = computed(() => this.requestCount() > 0);

  startRequest(): void {
    this.requestCount.update(count => count + 1);
  }

  endRequest(): void {
    this.requestCount.update(count => count - 1);
  }
}
