import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(private snackBar: MatSnackBar) { }

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      this.showErrorToast('An error occurred !')
    }
  }

  private showErrorToast(message: string) {
    this.snackBar.open(message, 'close', {
      duration: 3000,
      panelClass: ['error-toast']
    });
  }
}
