import {Injectable, signal} from '@angular/core';
import {PersonService} from "../person/person.service";
import {AttendancesService} from "../attendances/attendances.service";
import {GroupsService} from "../groups/groups.service";
import {forkJoin, map, mergeAll, mergeMap, Observable, switchMap, tap, toArray} from "rxjs";
import {GroupModel} from "../../models/group.model";
import {GroupAttendance, GroupsAttendance} from "../../models/group-attendance.model";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class GroupAttendanceService {

  groupAttendance = signal<GroupsAttendance>({totalPersonPresent: 0, groupAttendances: []});

  constructor(private httpClient: HttpClient) { }

  fetchGroupAttendanceBySession(sessionId: number): void {
    this.httpClient.get<GroupsAttendance>(`/api/group-attendance/${sessionId}`)
      .pipe(
        tap((groupsAttendance) => this.groupAttendance.set(groupsAttendance)),
      )
      .subscribe()
  }
}
