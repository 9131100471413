import {Component, EventEmitter, Input, Output, signal} from '@angular/core';
import {Observable} from "rxjs";
import {PersonModel} from "../../../models/person.model";
import {Session} from "../../../models/session.model";
import {Attendances} from "../../../models/attendances.model";

@Component({
  selector: 'app-table-attendance',
  templateUrl: './table-attendance.component.html',
  styleUrls: ['./table-attendance.component.scss']
})
export class TableAttendanceComponent {
  @Input({required: true}) personByGroup: PersonModel[] = [];
  @Input({required: true}) currentSession: Session | null = null;
  @Input({required: true}) attendanceMap = new Map<string,Attendances>();

  @Output() presentEvent = new EventEmitter();
  @Output() lateEvent = new EventEmitter();

  enableEdit = signal(false);

  handlePresent(event: Event, person: PersonModel) {
    this.presentEvent.next({event, person});
  }

  handleLate(event: Event, person: PersonModel) {
    this.lateEvent.next({event, person});
  }

  toggleEditMode() {
    this.enableEdit.update(edit => !edit);
  }

  protected readonly isNaN = isNaN;
}
