import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {finalize, Observable} from 'rxjs';
import {ApiLoadingService} from "../services/api-loading/api-loading.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private apiRequestService: ApiLoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.apiRequestService.startRequest();

    return next.handle(req).pipe(
      finalize(() => {
        this.apiRequestService.endRequest();
      })
    );
  }
}
