<!-- Main navbar -->
<div *ngIf="isLoggedIn$ | async" class="navbar navbar-expand-xl navbar-static shadow">
  <div class="container-fluid">
    <div class="navbar-brand flex-1">
      <a href="../../../index.html" class="d-inline-flex align-items-center">
        <h1 class="edu-monitor d-sm-inline-block h-16px invert-dark ms-3">
          <span class="edu">Edu</span><span class="monitor">Monitor</span>
        </h1>

        <!--        <img src="../../../assets/images/logo_text_dark.svg" class="d-none d-sm-inline-block h-16px invert-dark ms-3" alt="">-->
      </a>
    </div>

    <div *ngIf="!forcePasswordReset()" class="d-flex w-100 w-xl-auto overflow-auto overflow-xl-visible scrollbar-hidden border-top border-top-xl-0 order-1 order-xl-0 pt-2 pt-xl-0 mt-2 mt-xl-0">
      <ul class="nav gap-1 justify-content-center flex-nowrap flex-xl-wrap mx-auto">
        <li class="nav-item">
          <a routerLink="/attendance" routerLinkActive="active" class="navbar-nav-link rounded">
            Attendance
          </a>
        </li>

        <li *ngIf="isAdmin()" class="nav-item">
          <a routerLink="/groups" routerLinkActive="active" class="navbar-nav-link rounded">
            Groups
          </a>
        </li>

        <li *ngIf="isAdmin()" class="nav-item">
          <a routerLink="/encadrants-attendance" routerLinkActive="active" class="navbar-nav-link rounded">
            Encadrants
          </a>
        </li>
      </ul>
    </div>

    <ul class="nav gap-1 flex-xl-1 justify-content-end order-0 order-xl-1">

      <li class="nav-item">
        <app-upload-file [isUpload]="true"></app-upload-file>
      </li>
      <li class="nav-item">
        <button (click)="logout()" class="btn btn-outline-primary btn-icon rounded-pill"><i class="ph-sign-out"></i></button>
      </li>

    </ul>
  </div>
</div>
<div>
  <mat-progress-bar *ngIf="isLoading()" color="primary" mode="indeterminate"></mat-progress-bar>
</div>
